import(/* webpackMode: "eager" */ "/vercel/path0/apps/club-manager/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/apps/club-manager/src/@/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipProvider"] */ "/vercel/path0/apps/club-manager/src/@/components/ui/tooltip.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/club-manager/src/hooks/AdminContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ApolloWrapper"] */ "/vercel/path0/apps/club-manager/src/lib/client/apollo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-author\",\"src\":\"../../public/fonts/Author-Variable.ttf\"}],\"variableName\":\"author\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-satoshi\",\"src\":\"../../public/fonts/Satoshi-Variable.ttf\"}],\"variableName\":\"satoshi\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/auth/src/context/AuthProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/styles/globals.css");
